import React, { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import { Box, Button, IconButton } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { makeStyles } from 'tss-react/mui';
import { Actions, Loading, PageSearchBox, Typography } from '../ui';
import {
    DataGridPremium,
    GridToolbarContainer,
    useGridApiContext,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { useSetRecoilState } from 'recoil';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { alertState, severity } from '../app/recoil';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchExpertById } from '../instructor/slices/expertSummarySlice';

const useStyles = makeStyles()((theme) => ({
    header: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& button': {
            width: '100%',
            fontSize: '0.87rem',
            padding: theme.spacing(0.5, 1),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(0.5, 2),
            },
        },
    },
    tableContainer2: {
        height: '100%',
        maxHeight: 300,
        width: '100%',
        overflow: 'auto',
    },
    dialogAction: {
        marginRight: 'auto',
        marginLeft: '1rem',
        paddingBottom: '0.5rem',
    },
    linkTitle: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#1976d2',
        },
    },
    synopsis: {
        display: 'flex',
        gap: theme.spacing(1),
        corsor: 'pointer',
        alignItems: 'center',
    },
}));

const NewInstructorMatchingOnboardedModal = ({
    numOfMatchedOnboardedInstructors,
    requirementId,
    fetchData,
    addSearchParam = undefined,
    title,
    setAllRequirements,
    handleDialogClose = () => {},
}) => {
    const { classes } = useStyles();
    const [selectedRow, setSelectedRow] = useState([]);
    const [customSearchValue, setCustomSearchValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const setAlert = useSetRecoilState(alertState);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: [
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            'user_firstName',
            'user_lastName',
        ],
    });
    const dispatch = useDispatch();

    const fetchInstructors = useCallback(async () => {
        try {
            if (!requirementId) {
                return setRows([]);
            }
            const baseURL = '/api/instructors/matching-instructor';
            const params = new URLSearchParams();
            setLoading(true);
            if (searchValue) {
                params.append('searchValue', searchValue);
            }
            params.append('_id', requirementId);
            const url = `${baseURL}?${params.toString()}`;
            const { data } = await axios.get(url);
            setRows(data.instructors);

            if (data.instructors?.length != 0) {
                const res = await axios.put(`/api/client-requirements`, {
                    id: requirementId,

                    numOfMatchedOnboardedInstructors: data.instructors?.length,
                    // matchedInstructors2: rowsToSet,
                });

                if (setAllRequirements)
                    setAllRequirements((prev) => {
                        const requirementsCopy = [...prev];
                        const requirementToEdit = requirementsCopy.find(
                            ({ _id }) => _id == requirementId
                        );
                        requirementToEdit.numOfMatchedOnboardedInstructors =
                            data.instructors?.length;

                        return requirementsCopy;
                    });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [requirementId, searchValue, setAllRequirements]);

    useEffect(() => {
        fetchInstructors();
    }, [fetchInstructors]);

    const handleNameClick = useCallback((id) => {
        window.open('/admin/user-profiles/active/' + id, '_blank');
    }, []);

    const handleShowSummary = useCallback(
        (e, row) => {
            handleDialogClose();
            setTimeout(() => {
                dispatch(fetchExpertById(row._id));
            }, 300);
        },
        [dispatch, handleDialogClose]
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'user_firstName',
                headerName: 'First Name',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
                display: 'flex',
                renderCell: ({ row }) => {
                    return (
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNameClick(row.id)}
                        >
                            {row.user_firstName}
                        </span>
                    );
                },
            },
            {
                field: 'user_lastName',
                headerName: 'Last Name',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
                display: 'flex',
                renderCell: ({ row }) => {
                    return (
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNameClick(row.id)}
                        >
                            {row.user_lastName}
                        </span>
                    );
                },
            },
            {
                field: 'score',
                headerName: 'Fit',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return `${(value * 100).toFixed(2)}%`;
                },
            },
            {
                field: 'synopsis',
                headerName: 'Synopsis',
                width: 300,
                editable: false,
                filterable: false,
                sortable: false,
                valueFormatter: (value) => {
                    return value;
                },
                renderCell: ({ row }) => {
                    const rating =
                        typeof row.expertRating === 'number'
                            ? row.expertRating.toFixed(1)
                            : 'N/A';
                    return (
                        <Box
                            minWidth={280}
                            onClick={(e) => handleShowSummary(e, row)}
                            className={classes.synopsis}
                        >
                            <IconButton size="small">
                                <ContactPageIcon
                                    fontSize="small"
                                    color="primary"
                                />
                            </IconButton>
                            <Box sx={{ cursor: 'pointer' }}>
                                <Box>
                                    {row.inProgressCount} In Progress |{' '}
                                    {row.completedCount} Completed
                                </Box>
                                <Box>
                                    Location: {row.country} | Avg Rating:{' '}
                                    {rating}
                                </Box>
                            </Box>
                        </Box>
                    );
                },
            },
            {
                field: 'user_email',
                headerName: 'Email',
                width: 250,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'user_phone',
                headerName: 'Phone',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value ?? 'N/A';
                },
                display: 'flex',
                renderCell: ({ row }) => {
                    return <span>{row?.user_phone ?? 'N/A'}</span>;
                },
            },
            {
                field: 'user_country',
                headerName: 'Country',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'user_city',
                headerName: 'City',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'time_zone',
                headerName: 'Timezone',
                width: 300,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'user_linkedInUrl',
                headerName: 'LinkedIn',
                width: 340,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
                display: 'flex',
                renderCell: ({ row }) => {
                    return (
                        <a
                            href={row['user_linkedInUrl']}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                            }}
                        >
                            {row['user_linkedInUrl']}
                        </a>
                    );
                },
            },
            {
                field: 'plan_name',
                headerName: 'Plan',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'channelStatus',
                headerName: 'Channel Status',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'createdAt',
                headerName: 'Registered',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
            {
                field: 'updatedAt',
                headerName: 'Last Activity',
                width: 150,
                filterable: false,
                sortable: false,
                editable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
        ];
    }, [classes.synopsis, handleNameClick, handleShowSummary]);

    const handleItemSelection = (params) => {
        setSelectedRow(params);
    };

    const [actionsDisabled, setActionDisabled] = useState(false);

    const actions = [
        {
            name: 'Identify',
            onClick: async () => {
                setActionDisabled(true);
                await axios.post('/api/smes/add-users', {
                    clientRequirementIds: [requirementId],
                    userIds: selectedRow,
                    smeField: 'identifiedSmes',
                });
                setActionDisabled(false);
                setAlert({
                    message: 'Instructors marked as identified.',
                    severity: severity.SUCCESS,
                    show: true,
                });
                if (fetchData) await fetchData();
            },
        },
        {
            name: 'Shortlist',
            onClick: async () => {
                setActionDisabled(true);
                await axios.post('/api/smes/add-users', {
                    clientRequirementIds: [requirementId],
                    userIds: selectedRow,
                    smeField: 'shortListedSmes',
                });
                setActionDisabled(false);
                setAlert({
                    message: 'Instructors shortlisted.',
                    severity: severity.SUCCESS,
                    show: true,
                });
                if (fetchData) await fetchData();
            },
        },
    ];

    const handleClear = () => {
        setCustomSearchValue('');
        setSearchValue('');
        if (addSearchParam) {
            addSearchParam('');
        }
    };

    const handleSearch = () => {
        if (customSearchValue.trim().length > 0) {
            setSearchValue(customSearchValue);
            if (addSearchParam) {
                addSearchParam(customSearchValue);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.code === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Box>
            {/* <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={true}
                maxWidth="lg"
                classes={{ paper: classes.paper }}
            > */}

            <Box py={2}>
                <Box className={classes.header}>
                    <Typography component="h6" variant="h6">
                        Onboarded Instructors
                        {numOfMatchedOnboardedInstructors
                            ? ` (${numOfMatchedOnboardedInstructors}) `
                            : null}
                    </Typography>

                    <Box>
                        <Actions
                            actions={actions}
                            disabled={
                                selectedRow.length === 0 || actionsDisabled
                            }
                        />
                    </Box>
                </Box>
                <Box mb={2}>
                    <PageSearchBox
                        searchValue={customSearchValue}
                        setSearchValue={setCustomSearchValue}
                        handleClear={handleClear}
                        handleKeyPress={handleKeyPress}
                        handleSearch={handleSearch}
                        placeholder={'Search instructors'}
                    />
                </Box>
                {loading ? (
                    <Loading height="300px" />
                ) : (
                    <Box className={classes.tableContainer2}>
                        <DataGridPremium
                            autoHeight
                            rows={rows}
                            columns={columns}
                            checkboxSelection
                            rowSelectionModel={selectedRow}
                            hideFooter={true}
                            onRowSelectionModelChange={(param) => {
                                handleItemSelection(param);
                            }}
                            loading={loading}
                            disableRowSelectionOnClick
                            // disableColumnMenu={true}
                            disableColumnFilter={true}
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{ toolbar: { title } }}
                            getRowHeight={() => 'auto'}
                        />
                    </Box>
                )}
                {/* </DialogContent> */}
                {/* <DialogActions> */}
                {/* <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={handleShowMore}
                                >
                                    {isShowMoreClicked
                                        ? 'SHOW LESS'
                                        : 'SHOW MORE'}
                                </Button>
                            </Box> */}
                {/* </DialogActions> */}
            </Box>

            {/* </Dialog> */}
        </Box>
    );
};

function CustomToolbar({ title }) {
    const apiRef = useGridApiContext();

    const handleExport = (options) => {
        apiRef.current.exportDataAsCsv(options);
    };

    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt'
    );

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };

    const getRows = ({ apiRef }) => {
        if (apiRef?.current?.state?.rowSelection?.length > 0) {
            return apiRef.current.state.rowSelection;
        }

        const rows = apiRef.current?.state?.rows?.dataRowIds ?? [];
        return rows.slice(0, 5);
    };

    return (
        <GridToolbarContainer>
            <Button
                {...buttonBaseProps}
                onClick={() =>
                    handleExport({
                        getRowsToExport: getRows,
                        fileName: `${title}-onboardedInstructors`,
                    })
                }
                disabled={
                    apiRef?.current?.state?.rows?.dataRowIds?.length === 0
                }
            >
                Export
            </Button>
        </GridToolbarContainer>
    );
}

export default NewInstructorMatchingOnboardedModal;
